/*
 * @Author: your name
 * @Date: 2022-01-18 15:48:53
 * @LastEditTime: 2022-12-19 14:35:06
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\views\solution\solution.ts
 */
export default [
    {
        title:"智慧急救管控平台",
        conten:"",
        text:"上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力..."
    },
    {
        title:"院前院内危重联动系统-院前",
        conten:"",
        text:"上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力..."
    },
    {
        title:"院前院内危重联动系统-院内",
        conten:"",
        text:"上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力..."
    },
    {
        title:"远程医疗协同会诊-院前急救",
        conten:"",
        text:"上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力..."
    },
    {
        title:"救护车智能管理",
        conten:"",
        text:"上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力..."
    }
    
]